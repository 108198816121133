<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-input
						v-model="searchForm.username"
						placeholder="用户名"
						clearable
				>
				</el-input>
			</el-form-item>

			<el-form-item>
				<el-button @click="getUserList">搜索</el-button>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="handleAddForm()" v-if="hasAuth('sys:agent')">新增</el-button>
			</el-form-item>
			<!-- <el-form-item>
				<el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
					<el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:agent')">批量删除</el-button>
				</el-popconfirm>
			</el-form-item> -->
		</el-form>

		<el-table
				ref="multipleTable"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				border
				stripe
				>
				<!-- @selection-change="handleSelectionChange" -->

			<!-- <el-table-column
					type="selection"
					width="55">
			</el-table-column> -->

			<!-- <el-table-column
					label="头像"
					width="50">
				<template slot-scope="scope">
					<el-avatar size="small" :src="scope.row.avatar"></el-avatar>
				</template>
			</el-table-column> -->

			<el-table-column
					prop="username"
					label="用户名"
					width="220">
			</el-table-column>
			<el-table-column
					prop="code"
					width="150"
					label="代理等级">
				<template slot-scope="scope">
					<!-- 从roles中读取 -->
					<el-tag size="small" type="info" v-for="item in scope.row.sysRoles">{{item.name}}</el-tag>
				</template>

			</el-table-column>
			<el-table-column
					prop="city"
					label="城市">
			</el-table-column>
			<el-table-column
					prop="phoneNumber"
					label="手机号"
					width="150">
			</el-table-column>

			<el-table-column
					prop="statu"
					label="状态"
					width="70"
					>
				<template slot-scope="scope">
					<el-tag size="small" v-if="scope.row.statu === 1" type="success">正常</el-tag>
					<el-tag size="small" v-else-if="scope.row.statu === 0" type="danger">停用</el-tag>
				</template>

			</el-table-column>
			<el-table-column
					prop="accountNumShow"
					width="130"
					label="已开/可开会员数">
			</el-table-column>
			<el-table-column
					prop="subAgentNumShow"
					width="180"
					label="已开/可开二级代理商数">
			</el-table-column>
			
			<el-table-column
					prop="created"
					width="100"
					label="创建日期"
					:formatter="dateFormat"
					
			>
			</el-table-column>
			<el-table-column
					prop="rd"
					width="200"
					label="剩余时长(分)"
			>	
			</el-table-column>
			<el-table-column
					prop="parentName"
					label="上级用户"
					v-if="hasAuth('ROLE_admin')"
			>	
			</el-table-column>
			<el-table-column
					prop="icon"
					
					label="操作">

				<template slot-scope="scope">
					<!-- <el-button type="text" @click="roleHandle(scope.row.id)">分配角色</el-button>
					<el-divider direction="vertical"></el-divider> -->

					<el-button type="text" @click="repassHandle(scope.row.id, scope.row.username)">重置密码</el-button>
					<el-divider direction="vertical"></el-divider>

					<el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>
					<!-- <el-button type="text" @click="rdHandle(scope.row.id)">设置时长</el-button>
					<el-divider direction="vertical"></el-divider> -->
					<template>
						<el-popconfirm title="确认停用此用户吗" @confirm="deactive(scope.row.id)">
							<el-button type="text" slot="reference">停用</el-button>
						</el-popconfirm>
					</template>

				</template>
			</el-table-column>

		</el-table>

		<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes, prev, pager, next, jumper"
				:page-sizes="[10, 20, 50, 100]"
				:current-page="current"
				:page-size="size"
				:total="total">
		</el-pagination>


		<!--新增/编辑对话框-->
		<el-dialog
				title="提示"
				:visible.sync="dialogVisible"
				width="600px"
				:before-close="handleClose">
			<el-form :model="editForm" :rules="editFormRules" ref="editForm">
				<el-form-item label="用户名" prop="username" label-width="150px" :key="usernameInputKey">
					<el-input v-model="editForm.username" autocomplete="off" :disabled="username_disabled"></el-input>
					<el-alert
							title="初始密码为888888"
							:closable="false"
							type="info"
							style="line-height: 12px;"
					></el-alert>
				</el-form-item>

				<el-form-item label="城市"  prop="cityOptions" label-width="150px">
					<el-cascader size="large" :options="el_cityOptions" v-model="editForm.cityOptions"  />
					<!-- @change="handleChange" -->
				</el-form-item>
				<el-form-item label="手机号"  prop="phoneNumber" label-width="150px">
					<el-input v-model="editForm.phoneNumber" autocomplete="off"></el-input>
				</el-form-item>

				<el-form-item label="状态"  prop="statu" label-width="150px">
					<el-radio-group v-model="editForm.statu">
						<el-radio  :label="0">停用</el-radio>
						<el-radio  :label="1">正常</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="可开二级代理商数量"  prop="totalSubNum" label-width="150px" >
					<el-input v-model="editForm.totalSubNum" autocomplete="off" :disabled="subTotalAgentNumActiveCheck()"></el-input>
				</el-form-item>
				<el-form-item label="可开会员账号数量"  prop="totalAccountNum" label-width="150px">
					<!-- <el-input v-model="editForm.totalAccountNum" autocomplete="off"></el-input>
					 -->
					 <el-input-number v-model="editForm.totalAccountNum" :max="max_totalAccountNum" :min="min_totalAccountNum"></el-input-number>
				</el-form-item>
				<el-form-item label="总时长（分）"  prop="rd" label-width="150px">
					<!-- <el-input v-model="editForm.rd" autocomplete="off"></el-input> -->
					<el-input-number v-model="editForm.rd" :max="max_rd" :min="min_rd"></el-input-number>
				</el-form-item>
				<el-form-item label="代理等级"  prop="agentLevel" label-width="150px">
					<el-select  id="agentLevelSelect" v-model="editForm.agentLevel" placeholder="请选择" :disabled="!hasAuth('ROLE_admin')">
						<el-option
						v-for="item in agentLevelOptions"
						:label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				
			</el-form>
			<div slot="footer" class="dialog-footer" >
				<el-button @click="resetForm('editForm')">取 消</el-button>
				<el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
			</div>
		</el-dialog>


	</div>
</template>

<script>
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
	export default {
		name: "Agent",
		data() {
			return {
				usernameInputKey:0,//让“用户名”输入框的css样式重新进行渲染设置的一个flg
				max_totalAccountNum: 9999,//临时最大值，点击“新增”时获取真实最大值
				max_rd:9999,
				min_totalAccountNum:0,
				min_rd:0,
				min_totalAccountNum: 0,//新增/编辑代理商时，对最低会员数的限制。
				username_disabled: false,//用户名输入框的活性控制flg
				// city_options : cities.city_options,
				el_cityOptions: provinceAndCityData,
				agentLevelOptions: 
				[	{
						value: 1,
						label: '一级代理商'
					}, 
					{
						value: 2,
						label: '二级代理商'
				}],
				
				searchForm: {},
				delBtlStatu: true,

				total: 0,
				size: 10,
				current: 1,

				dialogVisible: false,
				setDurationDialogVisible:false,

				//“编辑”对应对话框formdata
				editForm: {
				
				},
				durationForm:{
					
				},

				tableData: [],

				//todo
				editFormRules: {
					allUsername:{},
					username: [
						{required: true, message: '请输入用户名称', trigger: 'blur'},
						{ validator: this.checkUsername, trigger: 'blur'},
						{
							min: 4,
							max: 20,
							message: "长度在 4 到 20 个字符",
							trigger: "blur",
						},
					],
					cityOptions: [
						{required: true, message: '请选择城市', trigger: 'blur'}
					],
					email: [
						{required: true, message: '请输入邮箱', trigger: 'blur'}
					],
					totalSubNum: [
						{required: true, message: '请输入二级代理商数量', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					],
					totalAccountNum: [
						{required: true, message: '请输入会员账号数量', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					],
					phoneNumber:[
						{pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号', trigger: 'blur'}
					],
					rd: [
						{required: true, message: '请输入总时长', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					],
					statu: [
						{required: true, message: '请选择状态', trigger: 'blur'},
					],
					agentLevel:[
						{required: true, message: '请选择代理商等级', trigger: 'blur'},
					]
				},
				durationFormRules:{
					rd: [
						{required: true, message: '请输入时长', trigger: 'blur'},
						{pattern: /^[0-9]*$/, message: '请输入正整数', trigger: 'blur'}
					]
				},

				multipleSelection: [],

				roleDialogFormVisible: false,
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				roleForm: {},
				roleTreeData:  [],
				treeCheckedKeys: [],
				checkStrictly: true

			}
		},
		created() {
			this.getUserList();
			
			// this.$axios.get("/sys/role/list").then(res => {
			// 	this.roleTreeData = res.data.data.records
			// })
		},
		// watch(){

			
		// },
		methods: {
			initNumberCheck(){
				//请求初始数据的限定值
				this.$axios.get('/sys/agent/get/initInfo').then(res => {
					this.min_totalAccountNum = res.data.data.totalAccountNum
					// this.editForm.totalAccountNum = this.min_totalAccountNum
					// console.log("res.data.data.totalAccountNum",res.data.data.totalAccountNum)
					this.min_rd = res.data.data.rd
				})
			},

			//停用代理商
			deactive(id){
				this.$axios.get("/sys/agent/deactive?id="+id).then(res => {//不存在：true；已存在：false
					this.$message({
						showClose: true,
						message: '禁用成功',
						type: 'success',
						onClose:() => {
							this.getUserList()
						}
					});
				})
			},	

			//检测“可开二级代理商数量“是否为活性，totalSubNum能否为活性 ture:非活性，false:活性
			subTotalAgentNumActiveCheck(){


				//1.活性条件1--当前执行操作的用户为一级代理商以上权限
				let check1 = this.hasAuth('ROLE_admin');
				//1.活性条件2--当前选中的“代理等级不为‘二级代理商’”）
				let check2 = (this.editForm.agentLevel != 2);
				//顺便处理当代理等级选中为“二级代理商”时，清空“可开二级代理商数量”
				if(!check2)
					this.editForm.totalSubNum = 0;
				return !(check1&&check2);
			},
			//检测用户名是否已存在
			checkUsername(rule,value,callback){
				//axios请求后台 对输入用户名进行检测
				this.$axios.get("/sys/user/username/check?username="+value).then(res => {//不存在：true；已存在：false
					if(res.data.data)
						callback();
					else
						callback(new Error('用户名已存在'));
				})
				
			},

			
			//点击“新增”按钮时初始化数据
			handleAddForm(){
				
				//请求初始数据的限定值
				this.$axios.get('/sys/agent/get/initInfo').then(res => {
					this.min_totalAccountNum = res.data.data.totalAccountNum
					this.min_rd = res.data.data.rd
					//在新增对话框里，给对应输入框写入初始值
					this.editForm.totalAccountNum = this.min_totalAccountNum
					this.editForm.rd = this.min_rd

					//获取操作者信息，提供max信息给el组件的输入框
					this.$axios.get('/sys/user/get/operator').then(res => {
						//this.min_totalAccountNum = 0 //已转交给get/initInfo请求处理
						this.max_totalAccountNum = res.data.data.totalAccountNum - res.data.data.soldAccountNum
						this.max_rd = res.data.data.rd
						this.username_disabled = false;

						//如果没有username——check，加上
						if(this.editFormRules.username == undefined){
							this.editFormRules.username = [
								{required: true, message: '请输入用户名称', trigger: 'blur'},
								{ validator: this.checkUsername, trigger: 'blur'},
								{
									min: 4,
									max: 20,
									message: "长度在 4 到 20 个字符",
									trigger: "blur",
								}
							];
						}
						this.usernameInputKey ++;


						this.dialogVisible = true;
						if(this.$refs['editForm'] != undefined)this.$refs['editForm'].clearValidate();//操作了validate的rule后，不清除validate状态会出现问题
						//一级代理商操作时，将默认值设置为2————二级代理商
						if(this.hasAuth('sys:agent')&&!this.hasAuth('ROLE_admin')){
							this.editForm.agentLevel = 2;
						}
					})	
				})
				
				
				
			},
			dateFormat(row,column){
				var t=new Date(row.created);//row 表示一行数据, updateTime 表示要格式化的字段名称
        		return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()
				//return t.getFullYear()+"-"+(t.getMonth()+1)+"-"+t.getDate()+" "+t.getHours()+":"+t.getMinutes();
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;

				this.delBtlStatu = val.length == 0
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.size = val
				this.getUserList()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.current = val
				this.getUserList()
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false
				
				this.editForm = {}
			},
			resetDurationForm(formName){
				this.$refs[formName].resetFields();
				this.setDurationDialogVisible = false;
				this.durationForm = {}
			},
			handleClose() {
				this.resetForm('editForm')
			},
			handleDurationClose(){
				this.resetDurationForm('durationForm')
			},

			getUserList() {
				this.$axios.get("/sys/agent/list", {
					params: {
						username: this.searchForm.username,
						current: this.current,
						size: this.size
					}
				}).then(res => {
					this.tableData = res.data.data.records
					//debug 
                    console.log("res.data.data.records",res.data.data.records);
					this.size = res.data.data.size
					this.current = res.data.data.current
					this.total = res.data.data.total
				})
                
			},

			submitForm(formName) {//todo
				console.log("submit:this.editForm ",this.editForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post('/sys/agent/' + (this.editForm.id?'update' : 'save'), this.editForm)
							.then(res => {

								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getUserList()
									}
								});
								this.resetForm(formName)//清理掉对话框中的信息，避免再次点击“新增”按钮会有上次的信息
							})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//设置时长
			// setDuration(formName){
			// 	this.$refs[formName].validate((valid) => {
			// 		if (valid) {
			// 			//提交一级代理商的id、新设置的时长  
			// 			this.submitAgentForm();
			// 		} else {
			// 			console.log('error submit!!');
			// 			return false;
			// 		}
			// 	});
			// },
			//新增一级代理商 or 编辑一级代理商 or 设置一级代理商时长
			submitAgentForm(){
				this.$axios.post('/sys/agent/' + (this.durationForm.id?'update' : 'save'), this.durationForm)
							.then(res => {

								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getUserList()
									}
								});
								
								this.setDurationDialogVisible = false
							})
			},
			editHandle(row) {
				// console.log("row-------",row)
				//去除“用户名”的相关check
				this.$delete(this.editFormRules,'username');
				//将“用户名”输入框设置为非活性
				this.username_disabled = true;
				
				this.initNumberCheck();//todo
				this.$axios.get('/sys/user/info/' + row.id +'?getCityCodeFlg=true').then(res => {

					this.editForm = res.data.data

					if(this.min_totalAccountNum < this.editForm.soldAccountNum)
						this.min_totalAccountNum = this.editForm.soldAccountNum
					if(this.min_rd > this.editForm.rd){
						this.min_rd = this.editForm.rd
					}
					//获取操作者信息，提供max信息给el组件的输入框
					this.$axios.get('/sys/user/get/operator').then(res => {
						this.max_totalAccountNum = res.data.data.totalAccountNum - res.data.data.soldAccountNum + this.editForm.totalAccountNum
						this.max_rd = res.data.data.rd + this.editForm.rd
						this.dialogVisible = true
						if(this.$refs['editForm'] != undefined)
							this.$refs['editForm'].clearValidate();//刷新validate状态
					})
					
					
				})
			},
			
			repassHandle(id, username) {

				this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post("/sys/user/repass", id).then(res => {
						this.$message({
							showClose: true,
							message: '操作成功',
							type: 'success',
							onClose: () => {
							}
						});
					})
				})
			}
		}
	}
</script>

<style scoped>

	.el-pagination {
		float: right;
		margin-top: 22px;
	}
	.el-select-dropdown__item.selected {
		color: #606266;
		font-weight: 100;
	}

</style>